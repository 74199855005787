import { format } from 'date-fns';
import {
	differenceInCalendarDays,
	differenceInCalendarMonths,
	differenceInCalendarYears,
} from 'date-fns';
interface PostInfo {
	fields: {
		date: string;
	};
	frontmatter: {
		author: string;
	};
}

export const getPostInfo = (post: PostInfo): string => {
	const datePost = format(new Date(post.fields.date), 'MMMM do, yyyy');
	return `${post.frontmatter.author} — ${datePost}`;
};

export const getPostTimePass = (date: string): string => {
	const diffYear = differenceInCalendarYears(new Date(), new Date(date));
	if (diffYear > 0) {
		return `${diffYear} year${diffYear > 1 ? 's' : ''}`;
	}

	const diffMonths = differenceInCalendarMonths(new Date(), new Date(date));
	if (diffMonths > 0) {
		return `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
	}

	const diffDays = differenceInCalendarDays(new Date(), new Date(date));
	if (diffDays > 0) {
		return diffDays > 1 ? `${diffDays} days` : 'Yesterday';
	}

	return 'Today';
};
