import styled from 'styled-components';
import { Link } from 'gatsby';
import { WIDTH_BREAKPOINT_MD } from 'src/constants';

export const ArticleSidebarReference = styled.div`
	background: white;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
	padding: 30px;
`;

export const ArticleSidebarList = styled.div`
	list-style: none;
	margin: 0;
	margin-top: 35px;
	padding: 0;

	&:nth-child(1) {
		margin-top: 0;
	}
`;

export const ArticleSidebarHeadline = styled.h3`
	color: #a6a6a6;
	font-family: 'Muller', sans-serif;
	font-size: 0.61rem;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;
`;

export const StartToEncrypt = styled.a`
	border: 0;
	border-radius: 3px;
	color: #bb241d;
	cursor: pointer;
	display: inline-block;
	font-family: 'Muller', sans-serif;
	font-size: 0.77rem;
	height: 42px;
	letter-spacing: 1px;
	line-height: 44px;
	margin: 0;
	margin-top: 20px;
	text-align: center;
	text-transform: uppercase;
	transition: all 0.5s;
	white-space: nowrap;

	svg {
		margin-left: 5px;
	}
`;

export const ArticleSidebarLink = styled(Link)`
	display: block;
	font-family: 'Muller', sans-serif;
	font-size: 0.61rem;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 1.8;
	margin-top: 15px;
	text-transform: uppercase;

	&:hover {
		color: #bb241d;
	}
`;

export const ArticleSidebarContent = styled.div`
	margin-top: 55px;
	max-width: 700px;
	order: 3;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		margin-top: 0;
		max-width: 250px;
		order: 2;
		width: 25%;
	}
`;
