import React from 'react';
import { ReactComponent as ArrowIcon } from '../../styles/assets/images/ui/icons/icon-arrowRight-red.svg';
import { ArticleSidebarContent, StartToEncrypt } from './ArticleSidebar.dump';

const ArticleSidebar: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
	return (
		<ArticleSidebarContent>
			{children}
			<StartToEncrypt href="https://dashboard.virgilsecurity.com/signup?_ga=2.204126359.663961474.1587979553-219858853.1575970523">
				Start to encrypt
				<ArrowIcon />
			</StartToEncrypt>
		</ArticleSidebarContent>
	);
};

export default ArticleSidebar;
