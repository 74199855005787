import styled from 'styled-components';
import { Link } from 'gatsby';
import { WIDTH_BREAKPOINT_MD } from 'src/constants';

export const ArticleContent = styled.div`
	max-width: 690px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		max-width: 100%;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		width: 70%;
	}
`;

export const ArticleContentBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 155px 0 0;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		justify-content: space-between;
		padding: 135px 40px 0;
	}
`;

export const ArticleActions = styled.div`
	margin-top: 55px;
	order: 2;
	text-align: center;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		order: 3;
		text-align: initial;
	}
`;

export const ArticleContainer = styled.div`
	font-size: 0.88rem;
`;

export const ArticleList = styled.div`
	max-width: 700px;

	&:hover {
		h2 {
			color: #da322c;
		}
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		width: 70%;
	}
`;

export const ArticleItemContainer = styled(Link)`
	background: #ffffff;
	border-radius: 3px;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	min-height: 500px;

	&:nth-child(1) {
		margin-top: 0;
	}
`;

export const ArticleItemContent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: 0.88rem;
	padding: 35px 30px 30px;

	h2 {
		font-family: 'Muller', sans-serif;
		font-size: 1.11rem;
		letter-spacing: 1px;
		line-height: 1.3;
		text-transform: uppercase;
		transition: 0.5s color;
		width: 100%;
	}

	p {
		flex: 1;
		line-height: 1.6;
		margin-top: 10px;
		width: 100%;
	}
`;

export const ArticleItemImg = styled.div`
	background: #ffffff no-repeat center 16%;
	background-image: url(${(props: { banner: string }) => props.banner});
	background-size: cover;
	border-radius: 3px 3px 0 0;
	display: block;
	max-width: 100%;
	min-height: 265px;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		width: 700px;
	}
`;

export const ArticleItemInfo = styled.div`
	color: #a6a6a6;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 40px;
`;

export const ArticleItemTime = styled.div`
	align-items: center;
	display: flex;

	svg {
		margin-left: 10px;
	}
`;
