import React from 'react';
import { ArticleContainer, ArticleContentBlock } from './ArticleItem.dump';

const Article: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
	return (
		<ArticleContainer {...props}>
			<div className="wrapper">
				<ArticleContentBlock>{children}</ArticleContentBlock>
			</div>
		</ArticleContainer>
	);
};

export default Article;
